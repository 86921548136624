export default {
    HOME: 'home',
    SANDBOX: 'sandbox',
    USER_LIST: 'user-list',
    USER_CREATE: 'user-create',
    USER_EDIT: 'user-edit',
    USER_ADDRESS_LIST: 'user-address-list',
    USER_ADDRESS_CREATE: 'user-address-create',
    USER_ADDRESS_EDIT: 'user-address-edit',
    PROMOTION_LIST: 'promotion-list',
    PROMOTION_CREATE: 'promotion-create',
    PROMOTION_EDIT: 'promotion-edit',
    PASSWORD_RESET_REQUEST: 'password-reset-request',
    PASSWORD_RESET: 'password-reset',
    LOGIN: 'login',
    LOGIN_TOKEN: 'login-token',
    CANTEEN_LIST: 'canteen-list',
    CANTEEN_BALANCE_LIST: 'canteen-balance-list',
    COMPANY_LIST: 'company-list',
    COMPANY_CREATE: 'company-create',
    COMPANY_EDIT: 'company-edit',
    COMPANY_EMPLOYEES: 'company-employees',
    COMPANY_TEAMS_LIST: 'company-teams',
    COMPANY_TEAM_CREATE: 'company-team-create',
    COMPANY_TEAM_EDIT: 'company-team-edit',
    COMPANY_ADDRESS_LIST: 'company-address-list',
    COMPANY_ADDRESS_CREATE: 'company-address-create',
    COMPANY_ADDRESS_EDIT: 'company-address-edit',
    COMPANY_ALLOWANCE_LIST: 'company-allowance-list',
    COMPANY_ALLOWANCE_CREATE: 'company-allowance-create',
    COMPANY_ALLOWANCE_EDIT: 'company-allowance-edit',
    COMPANY_BALANCE_LIST: 'company-balance-list',
    COMPANY_CLOUDCANTEEN_LIST: 'company-cloudcanteen-list',
    COMPANY_CLOUDCANTEEN_CREATE: 'company-cloudcanteen-create',
    COMPANY_CLOUDCANTEEN_EDIT: 'company-cloudcanteen-edit',
    COMPANY_SELF_EDIT: 'company-self-edit',
    COMPANY_SELF_ADDRESS_LIST: 'company-self-address-list',
    COMPANY_SELF_ADDRESS_CREATE: 'company-self-address-create',
    COMPANY_SELF_ADDRESS_EDIT: 'company-self-address-edit',
    COMPANY_SELF_ALLOWANCE_LIST: 'company-self-allowance-list',
    COMPANY_SELF_ALLOWANCE_CREATE: 'company-self-allowance-create',
    COMPANY_SELF_ALLOWANCE_EDIT: 'company-self-allowance-edit',
    COMPANY_INVOICES: 'company-invoices',
    ORDER_LIST: 'order-list',
    ORDER_EDIT: 'order-edit',
    ORDER_ACTIVE: 'order-active',
    ORDER_OPEN: 'order-open',
    ORDER_HISTORY: 'order-history',
    ORDER_PREVIEW_EMAIL: 'order-preview-email',
    ORDER_CONFIRM: 'order-confirm',
    RESTAURANT_LIST: 'restaurant-list',
    RESTAURANT_CREATE: 'restaurant-create',
    RESTAURANT_EDIT: 'restaurant-edit',
    RESTAURANT_GENERAL: 'restaurant-general',
    RESTAURANT_CLOUDCANTEEN_PUBLIC_PRINT_LABEL:
        'restaurant-cloudcanteen-public-print-label',
    RESTAURANT_CLOUDCANTEEN_PUBLIC_PRINT_SUMMARY:
        'restaurant-cloudcanteen-public-print-summary',
    RESTAURANT_INVOICES: 'restaurant-invoices',
    TRANSLATION_LIST: 'translation-list',
    TAG_LIST: 'tag-list',
    TAG_CREATE: 'tag-create',
    TAG_EDIT: 'tag-edit',
    TAG_GROUP_LIST: 'tag-group-list',
    TAG_GROUP_CREATE: 'tag-group-create',
    TAG_GROUP_EDIT: 'tag-group-edit',
    TABLETS_CONFIG: 'tablets-config',
    DELIVERY_LIST: 'delivery-list',
    DELIVERY_CREATE: 'delivery-create',
    DELIVERY_CREATE_PROVIDER_JOB: 'delivery-create-provider-job',
    DELIVERY_EDIT: 'delivery-edit',
    INVOICES_DASHBOARD: 'invoices-dashboard',
    INVOICES_CREATE: 'invoices-create',
    INVOICES_EDIT: 'invoices-edit',
    RESIDENTIAL_CANTEENS: 'residential-canteens',
    RESIDENTIAL_CANTEENS_CREATE: 'residential-canteens-create',
    RESIDENTIAL_CANTEENS_EDIT: 'residential-canteens-edit',
    RESIDENTIAL_CANTEEN_ADDRESSES: 'residential-canteen-addresses',
    RESIDENTIAL_CANTEEN_ADDRESSES_CREATE:
        'residential-canteen-addresses-create',
    RESIDENTIAL_CANTEEN_ADDRESSES_EDIT: 'residential-canteen-addresses-edit',
    RESIDENTIAL_CANTEEN_RELATIONS: 'residential-canteen-relations',
    RESIDENTIAL_CANTEEN_RELATIONS_CREATE:
        'residential-canteen-relations-create',
    RESIDENTIAL_CANTEEN_RELATIONS_EDIT: 'residential-canteen-relations-edit',
    CUSTOM_EXPORT_LIST: 'custom-export-list',
    CUSTOM_EXPORT_CREATE: 'custom-export-create',
    CUSTOM_EXPORT_EDIT: 'custom-export-edit',
    CUSTOM_EXPORT_SHOW: 'custom-export-show',
    CUSTOM_UPDATE_SHOW: 'custom-update-show',
    CUSTOM_EXPORT_CATEGORY_LIST: 'custom-export-category-list',
    CUSTOM_EXPORT_CATEGORY_CREATE: 'custom-export-category-create',
    CUSTOM_EXPORT_CATEGORY_EDIT: 'custom-export-category-edit',
    COUPON_LIST: 'coupon-list',
    COUPON_CREATE: 'coupon-create',
    COUPON_EDIT: 'coupon-edit',
    REGISTRATION_CODES_LIST: 'registration-codes-list',
    REGISTRATION_CODES_CREATE: 'registration-codes-create',
    REGISTRATION_CODES_EDIT: 'registration-codes-edit',
    PROMO_BANNERS_LIST: 'promo-banners-list',
    PROMO_BANNERS_CREATE: 'promo-banners-create',
    PROMO_BANNERS_EDIT: 'promo-banners-edit',
    SETTINGS: 'settings',
    RESTAURANT_HIGHLIGHTS: 'restaurant-highlights',
    AUDIT_LOG_LIST: 'audit-log-list',
    AUDIT_LOG_HISTORY: 'audit-log-history',
    TODO_LIST: 'todo-list',
    TODO_CREATE: 'todo-create',
    TODO_EDIT: 'todo-edit',
    BRAND_LIST: 'brand-list',
    BRAND_CREATE: 'brand-create',
    BRAND_EDIT: 'brand-edit',
    PUSH_NOTIFICATIONS: 'push-notifications',
}
